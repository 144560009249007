import * as React from "react"
import SEO from "../components/SEO"
import Layout from "../components/Layout"

const ServicePage = () => {

  return (
    <Layout>
        <SEO title="Services" description="Learn more about the services I provide and how they suit every size of small business." />
        <main>
            <title>Services</title>
            <div className="container d-flex flex-column align-items-center">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active leftServicesButton" id="pills-details-tab" data-bs-toggle="pill" data-bs-target="#pills-details" type="button" role="tab" aria-controls="pills-details" aria-selected="true">
                            Details
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link rightServicesButton" id="pills-plans-tab" data-bs-toggle="pill" data-bs-target="#pills-plans" type="button" role="tab" aria-controls="pills-plans" aria-selected="false">
                            Plans
                        </button>
                    </li>
                </ul>
                <div className="tab-content mt-3" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-details" role="tabpanel" aria-labelledby="pills-details-tab">
                        <div className="list-group services-list-group mx-2">
                            <a href="#" className="list-group-item list-group-item-action flex-column align-items-start">
                                <div className="d-flex justify-content-between">
                                    <h5 className="mb-1">No Upfront Cost</h5>
                                </div>
                                <p className="mb-1">
                                    The only cost to you for one of my websites is the monthly fee. 
                                </p>
                            </a>
                            <a href="#" className="list-group-item list-group-item-action flex-column align-items-start">
                                <div className="d-flex justify-content-between">
                                    <h5 className="mb-1">Unlimited Edits</h5>
                                </div>
                                <p className="mb-1">
                                    As many updates to the contents of your pages as you like.
                                </p>
                            </a>
                            <a href="#" className="list-group-item list-group-item-action flex-column align-items-start">
                                <div className="d-flex justify-content-between">
                                    <h5 className="mb-1">Responsive Design</h5>
                                </div>
                                <p className="mb-1">
                                    Your website will look great on any computer, phone or tablet.
                                </p>
                            </a>
                        </div>
                        <div className="list-group services-list-group mx-2">
                            <a href="#" className="list-group-item list-group-item-action flex-column align-items-start">
                                <div className="d-flex justify-content-between">
                                    <h5 className="mb-1">Search Engine Optimization</h5>
                                </div>
                                <p className="mb-1">A beautiful website is only helpful if people can find it.</p>
                            </a>
                            <a href="#" className="list-group-item list-group-item-action flex-column align-items-start">
                                <div className="d-flex justify-content-between">
                                    <h5 className="mb-1">Website Hosting</h5>
                                </div>
                                <p className="mb-1">All of my websites are hosted on fast and reliable servers.</p>
                            </a>
                            <a href="#" className="list-group-item list-group-item-action flex-column align-items-start">
                                <div className="d-flex justify-content-between">
                                    <h5 className="mb-1">Domain Name</h5>
                                </div>
                                <p className="mb-1">So you don't have to worry about your site going offline when you forget to renew your domain.</p>
                            </a>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="pills-plans" role="tabpanel" aria-labelledby="pills-plans-tab">
                        <div className="d-flex justify-content-center">
                            <div className="list-group plans-list-group mx-2">
                                <a href="#" className="list-group-item list-group-item-action flex-column align-items-start">
                                    <div className="d-flex justify-content-between">
                                        <h5 className="mb-1">Small Small Business - $50/month</h5>
                                        <small>1-4 pages</small>
                                    </div>
                                    <p className="mb-1">
                                        Perfect for getting your name out there, giving your customers an overview of
                                        what products or services you offer and how to contact you.
                                    </p>
                                </a>
                                <a href="#" className="list-group-item list-group-item-action flex-column align-items-start">
                                    <div className="d-flex justify-content-between">
                                        <h5 className="mb-1">Medium Small Business - $100/month</h5>
                                        <small>5-7 pages</small>
                                    </div>
                                    <p className="mb-1">
                                        Everything in the Small Small Business plan but with more room for details
                                        about your products or services.
                                    </p>
                                </a>
                                <a href="#" className="list-group-item list-group-item-action flex-column align-items-start">
                                    <div className="d-flex justify-content-between">
                                        <h5 className="mb-1">Big Small Business - $150/month</h5>
                                        <small>8+ pages</small>
                                    </div>
                                    <p className="mb-1">
                                        Anything and everything that you want your customers to know about you
                                        down to the nitty gritty.
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </Layout>
  )
}
  
export default ServicePage